// Vietnamese
  export default {
    login: 'Đăng nhập',
    logout: 'Đăng xuất',
    messages: 'Tin nhắn',
    newMessage: 'Tin nhắn mới',
    sendMessage: 'Gửi tin nhắn',
    chat: 'Trò chuyện',
    chatroom: 'Phòng trò chuyện',
    chatList: 'Danh sách trò chuyện',
    settings: 'Cài đặt',
    language: 'Ngôn ngữ',
    SOS: 'SOS',
    medicalEmergency: 'Khẩn cấp y tế',
    overboard: 'Rơi xuống biển',
    pirate: 'Hải tặc',
    sinkOrCrash: 'Chìm hoặc va chạm',
  }