<template>
  <div>
    <v-toolbar dark>
      <img class="mr-3" src="@/assets/banner.png" height="40"/>
      <v-spacer />
      <v-toolbar-items>
        <v-btn v-for="(btn, idx) in items"
          :key="idx"
          :to="btn.link"
        >
          {{ btn.text }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
  </div>
</template>

<script>

export default {
  props:{
    items: Array,
    title: String
  },
};
</script>
