// THai
export default {
  login: "เข้าสู่ระบบ",
  logout: "ออกจากระบบ",
  messages: "ข้อความ",
  newMessage: "ข้อความใหม่",
  sendMessage: "ส่งข้อความ",
  chat: "แชท",
  chatroom: "ห้องแชท",
  chatList: "รายการแชท",
  settings: "การตั้งค่า",
  language: "ภาษา",
  SOS: "SOS",
  medicalEmergency: "ฉุกเฉินการแพทย์",
  overboard: "หลุดจากเรือ",
  pirate: "โจรสลัด",
  sinkOrCrash: "จมหรือชนกัน",
};
