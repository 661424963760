// English

export default {
  navbar: {
    home: "Home",
    about: "About MarineCom",
    pricing: "Pricing",
    login: "Login",
    logout: "Logout",
    dashboard: "Dashboard", 
  },
  home: {
    title1: "An",
    title2: "AFFORDABLE",
    title3: "option to",
    title4: "STAY CONNECTED,", 
    title5: "with", 
    title6: "families, ", 
    title7: "friends, ", 
    title8: "everyone", 
    title9: "even on the OCEAN", 
  },
  about: {
    textMessaging: {
      title: "Text Messaging", 
      availability: "Available at launch", 
      description: "Unlimited* 2-way text message to stay in touch with your family, friends, or tell your business partner to prepare to load for your bountiful catch when you are coming back to land."
    }, 
    sos: {
      title: "SOS",
      availability: "Available at launch", 
      description: "MarineCom SOS connects directly with Fisheries Monitoring Center (FMC) when under exceptional circumstances, respond any events you've encountered in no time."
    }, 
    crewManagement: {
      title: "Crew Management",
      availability: "Available at launch", 
      description: "Crew Management re-invented the process of clocking in/out, and how to manage your crew member whenever you need. More detail coming soon."
    }, 
    notes: {
      unlimited: "*Actual maximum data traffic varies depending on variables such as weather, user location, signal coverage, and other factors.", 
    }, 
  },
  pricing: {
    vesselOwner: {
      title: "Vessel Owner", 
      description: {
        text: "Vessel Owner Plan covers the monthly fee of the satellite communication for all the crew members, and an active MarineCom account.",
        price: "$97.99usd/month<br> + $34.95 One time Activation Fee",
      },
    }, 
    crewMemberIndividual: {
      title: "Crew Member - Individual", 
      description: {
        text: "Crew Member - Individual Plan is worthy of the name, it's for crew member who wants to stay connected with people on land.",
        price: "<br>$3.99/month",
      },
    }, 
    selectAndCheckOut: "Select and checkout", 
  },
  checkout: {
    checkout: "Checkout", 
    planSelected: "Plan selected: ", 
    pricing: {
      firstMonth: "first month, then", 
      perMonth: "per month"
    }
  },
  login: {
    login: "Login",
    or: "or", 
    signUp: "Sign Up", 
    account: "Account (Email)",
    password: "Password", 
  },
  signUp: {
    email: "Email", 
    account: "Account (Must less than 8 characters)", 
    password: "Password", 
    firstName: "First name",
    lastName: "Last name",
    phoneNumber: "Phone number", 
    signUp: "Sign Up", 
    haveAnAccount: "Already have an account?",
    login: "Login",
  }, 
  dashboard: {
    dashboard: "Dashboard", 
    accountDetail: {
      accountDetail: "Account Detail",
      uid: "UID",
      email: "Email",
      account: "Account",
      password: "Password",
      firstName: "First name",
      lastName: "Last name",
      phoneNumber: "Phone number", 
    }, 
    vesselDetail: {
      vesselDetail: "Vessel Detail"
    }, 
    subscriptionStatus: {
      subscriptionStatus: "Subscription Status", 
      activeSubscription: "Active Subscription",
      noActiveSubscription: "NO ACTIVE SUBSCRIPTION", 
      viewPricing: "View pricing", 
      since: "Since", 
      renews: "Renews", 
    }, 
  },
};
