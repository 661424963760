<template>
  <v-app>
    <span class="iridium-bg"></span>
    <v-layout column fill-height>
      <navibar :title="navbarConfig.title" :items="navbarConfig.items"></navibar>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-layout>
  </v-app>
</template>

<script>
import navibar from "@/components/navbar/navbar.vue";
// import apis from "@/apis";

export default {
  name: 'App',
  components: { navibar, }, 
  data() {
    return {
      navbarConfig: {
        title: "MarineCom", 
        items: [
          { text: this.$t("ui.navbar.home"), link: "/" }, 
          { text: this.$t("ui.navbar.about"), link: "/about" }, 
          // { text: this.$t("ui.navbar.beta"), link: "/beta!" }, 
          // { text: this.$t("ui.navbar.pricing"), link: "/pricing" }, 
        ]
      }
    }
  },
  async created() {
    // TODO: user auth
    // this.$authPassed = await utils.checkLoginStatus();
    // if(this.$authPassed){
    //   this.navbarConfig.items.push({text: this.$t("ui.navbar.dashboard"), link: "/me"});
    //   this.navbarConfig.items.push({text: this.$t("ui.navbar.logout"), link: "/logout"});
    // }else{
    //   this.navbarConfig.items.push({text: this.$t("ui.navbar.login"), link: "/Login"});
    // }
  },
  async mounted() {
    // TODO: user auth
    // await apis.refreshUserToken();
  },
};
</script>


<style>
.bg-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  filter: brightness(0.5) blur(2px);
}
.backdrop-blur{
  backdrop-filter: brightness(0.5) blur(2px);
}
.iridium-bg{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("assets/app-bg2.jpg") no-repeat center right;
  backdrop-filter: brightness(0.5) blur(2px);
  background-size: cover;
}
.fade-enter-active, .fade-leave-active {
  transition: 0.4s;
}
.fade-enter {
  opacity: 0;
  /* transform: translateX(-20vh); */
  /* transform: scale(0.95) */
}
.fade-leave-to {
  opacity: 0;
  /* transform: translateX(20vh); */
  /* transform: scale(0.95) */
}
</style>