<template>
  <v-container fluid fill-height class="backdrop-blur">
    <v-layout>
      <v-flex d-flex justify-center align-center class="text-xs-center">
        <v-row lign="center" justify="center">
          <v-expand-transition>
            <v-card
              v-if="!hideRegistration"
              class="py-9 px-10 floating-window"
              elevation-10
            >
              <v-form ref="form" @submit.prevent="submit" lazy-validation>
                <v-card-title class="justify-center text-h4" primary-title>
                  註冊 MarineCom 帳號
                </v-card-title>
                <v-card-text class="mt-3">
                  <v-text-field
                    label="帳號 | Account"
                    :rules="[(v) => !!v || '帳號為必填']"
                    v-model="username"
                  />
                  <v-text-field
                    label="密碼 | Password"
                    :rules="[(v) => !!v || '密碼為必填']"
                    :type="showPass ? 'text' : 'password'"
                    :append-icon="showPass ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="showPass = !showPass"
                    v-model="password"
                  />
                  <v-text-field
                    label="電子郵件 | Email"
                    :rules="[(v) => !!v || 'Email 為必填']"
                    v-model="email"
                  />
                  <v-text-field
                    label="身分證/居留證編號 | NIC/RC ID number"
                    :rules="[(v) => !!v || '身分證/居留證編號 為必填']"
                    v-model="idNumber"
                  />
                  <v-text-field 
                    label="真實姓名 | Real name" 
                    :rules="[(v) => !!v || '請填寫您的姓名']"
                    v-model="name" 
                  />
                  <v-text-field
                    label="電話號碼 | Phone number"
                    :rules="[(v) => !!v || '請填入您的電話號碼']"
                    v-model="phone"
                  />
                  <v-select
                    :items="plans"
                    v-model="plan"
                    label="計畫 | Plan"
                    :rules="[(v) => !!v || '請選擇一個計畫']"
                  ></v-select>
                </v-card-text>
                <v-card-actions class="mb-2">
                  <v-spacer />
                  <div id="pay-with-paypal"></div>
                  <paypal-button 
                    :on-approve="submit"
                  />
                  <!-- <v-btn type="submit" outlined color="primary"
                    >註冊 | Register</v-btn
                  > -->
                </v-card-actions>
              </v-form>
            </v-card>
          </v-expand-transition>
          <v-expand-transition class="delay-600">
            <v-card
              v-if="accountCreated"
              class="py-9 px-10 floating-window wide"
              elevation-10
            >
              <v-card-title primary-title> 帳號已成功建立！ </v-card-title>
              <v-card-text>
                <div class="reminder warning--text">
                  請記下您的帳號 ID 以用來登入 MarineCom 手機 APP
                </div>
                <div class="reminder warning--text">
                  或是使用 ID 與密碼登入
                  <a
                    href="https://northrend.deepsea9.taipei/marinecom-webchat/login"
                    >網頁版聊天室</a
                  >
                </div>
                <div class="account-details">
                  <v-row class="warning--text" align="center" justify="center">
                    <v-col>帳號 ID | UserID:</v-col>
                    <v-col
                      >{{ uid }}
                      <!-- <v-btn small icon color="warning">
                    <v-icon small>mdi-content-copy</v-icon>
                  </v-btn> -->
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center">
                    <v-col>帳號 | Account:</v-col>
                    <v-col>{{ username }}</v-col>
                  </v-row>
                  <v-row align="center" justify="center">
                    <v-col>電子郵件 | Email:</v-col>
                    <v-col>{{ email }}</v-col>
                  </v-row>
                  <v-row align="center" justify="center">
                    <v-col>電話號碼 | Phone:</v-col>
                    <v-col>{{ phone }}</v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-expand-transition>
        </v-row>
        <div class="credits">
          <img src="@/assets/phasetech-logo.png" />
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      showPass: false,
      username: "",
      password: "",
      idNumber: "",
      email: "",
      name: "",
      phone: "",
      uid: "",
      hideRegistration: false,
      accountCreated: false,
      plans: ["Marine", "Land"], 
      plan: null, 
    };
  },
  mounted() {
    // this.$paypal.Buttons().render("#pay-with-paypal")
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;
      // const data = await apis.createUser(
      //   this.username,
      //   this.password,
      //   this.name,
      //   this.email,
      //   this.phone,
      //   this.idNumber
      // );
      const data = {uid: "test#0000"}
      this.uid = data.uid;
      this.hideRegistration = true;
      setTimeout(() => {
        this.accountCreated = true;
      }, 500);
    },
    createOrder(data){
      console.log(data);
      return {};
    }, 
  },
};
</script>

<style>
.z1 {
  z-index: 1;
}
.floating-window {
  background: #0a0a0a60 !important;
  backdrop-filter: blur(5px) !important;
}
#hover-3d {
  transform-style: preserve-3d;
  z-index: 1;
}
.reminder {
  font-size: 120%;
}
.account-details {
  box-sizing: border-box;
  padding-left: 1rem;
  font-size: 150%;
  margin-top: 2rem;
}
.v-dialog {
  max-width: 50%;
}
.credits {
  left: 0;
  overflow: hidden;
  position: absolute;
  bottom: 0.5rem;
  width: 100%;
  text-align: center;
}
.credits img {
  max-height: 3.5rem;
}

@media only screen and (max-width: 600px) {
  .credits img {
    max-height: 2rem !important;
  }
}
</style>
