// Indonesian
export default {
  login: "Masuk",
  logout: "Keluar",
  messages: "Pesan",
  newMessage: "Pesan Baru",
  sendMessage: "Kirim Pesan",
  chat: "Obrolan",
  chatroom: "Ruang Obrolan",
  chatList: "Daftar Obrolan",
  settings: "Pengaturan",
  language: "Bahasa",
  SOS: "SOS",
  medicalEmergency: "Darurat Medis",
  overboard: "Tenggelam",
  pirate: "Bajak Laut",
  sinkOrCrash: "Tenggelam atau Tabrakan",
};
