<template>
  <v-container fluid fill-height class="backdrop-blur">
    <v-layout>
      <v-flex d-flex justify-center align-center class="text-xs-center">
        <v-row align="center" justify="center" style="text-align: center">
          <v-col>
            <v-row
              class="px-0 py-0"
              align="center"
              justify="center"
              style="text-align: center"
            >
              <v-col>
                <div class="home-title a">{{ $t("ui.home.title1") }} <b>{{ $t("ui.home.title2") }}</b> {{ $t("ui.home.title3") }}</div>
              </v-col>
            </v-row>
            <v-row
              class="px-0 py-0"
              align="center"
              justify="center"
              style="text-align: center"
            >
              <v-col>
                <div class="home-title a a0">{{ $t("ui.home.title4") }}</div>
              </v-col>
            </v-row>
            <v-row
              class="px-0 py-0"
              align="center"
              justify="center"
              style="text-align: center"
            >
              <v-col>
                <div class="home-title a a1">{{ $t("ui.home.title5") }}</div>
                <span class="home-title a a2">{{ $t("ui.home.title6") }} </span>
                <span class="home-title a a3">{{ $t("ui.home.title7") }}</span>
                <span class="home-title a a4">{{ $t("ui.home.title8") }}</span>
              </v-col>
            </v-row>
            <v-row
              class="px-0 py-0"
              align="center"
              justify="center"
              style="text-align: center"
            >
              <v-col>
                <div class="home-title a a5">
                  <b class="hightlght a">{{ $t("ui.home.title9") }}</b>
                </div>
              </v-col>
            </v-row>
            <v-row
              class="px-0 py-0"
              align="center"
              justify="center"
              style="text-align: center"
            >
              <v-col>
                <!-- <div class="home-title a a6">a project by</div> -->
                <div class="home-title a a6">
                  <img class="product-logo" src="@/assets/banner.png" height="90"/>
                </div>
              </v-col>
            </v-row>
            <v-row
              class="px-0 py-0"
              align="center"
              justify="center"
              style="text-align: center"
            >
              <v-col>
                <div class="home-credits a a7">
                  <span class="home-title">by</span>
                  <a href="https://www.phasetech.tw/">
                    <img src="@/assets/phasetech-logo.png" />
                  </a>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
.home-title {
  font-size: 300%;
  color: #eaeaea;
}
.hightlght {
  color: #fff;
}

.a {
  animation: fade-in 0.6s ease-in alternate both;
}

.d {
  animation: fade-out 0.6s ease-in alternate both;
  animation-fill-mode: both;
}

.a.a0 {
  animation-delay: 0.6s;
}
.a.a1 {
  animation-delay: 1.4s;
}
.a.a2 {
  animation-delay: 2.2s;
}
.a.a3 {
  animation-delay: 3s;
}
.a.a4 {
  animation-delay: 3.8s;
}
.a.a5 {
  animation-delay: 4.6s;
}
.a.a6 {
  animation-delay: 5.4s;
}
.a.a7 {
  animation-delay: 6.2s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    display: none !important;
    width: 0px !important;
    visibility: hidden;
  }
}

.home-credits {
  overflow: hidden;
  /* position: absolute; */
  width: 100%;
  text-align: center;
}
.home-credits img {
  max-height: 4.5rem;
  vertical-align: middle;
}

@media only screen and (max-width: 600px) {
  .home-title {
    font-size: 185%;
    color: #eaeaea;
  }
  .product-logo{
    max-height: 4.5rem;
  }
  .home-credits .home-title{
    font-size: 130%;
  }
  .home-credits img{
    max-height: 3rem;
  }
}
</style>
