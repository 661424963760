import Vue from 'vue'
import VueRouter from 'vue-router'

// import utils from '@/utils'

import registration from '../components/registration.vue'
import home from '../components/home.vue'
// import pricing from '../components/pricing.vue'
// import checkout from '../components/checkout.vue'
// import login from '../components/login.vue'
// import logout from '../components/logout.vue'
// import signup from '../components/signup.vue'
import signupBeta from '../components/signupBeta.vue'
// import emailVerify from "../components/emailVerify.vue"
// import myPage from "../components/myPage.vue"
import about from "../components/about.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/registration',
    name: 'registration',
    component: registration 
  },
  {
    path: '/',
    name: 'home',
    component: home 
  },
  // {
  //   path: '/pricing',
  //   name: 'pricing',
  //   component: pricing 
  // },
  // {
  //   path: '/checkout',
  //   name: 'checkout',
  //   component: checkout,
  //   props: true, 
  // },
  // {
  //   path: '/login', 
  //   name: "login", 
  //   component: login 
  // }, 
  {
    path: '/sign-up', 
    name: 'signup', 
    component: signupBeta, 
  }, 
  // {
  //   path: '/verify-email/:uuid', 
  //   name: "emailVerify", 
  //   component: emailVerify 
  // }, 
  // {
  //   path: '/me', 
  //   name: 'me', 
  //   component: myPage, 
  //   meta: {
  //     auth: true
  //   }
  // }, 
  // {
  //   path: '/logout', 
  //   name: 'logout', 
  //   component: logout, 
  // }, 
  {
    path: '/about',
    name: 'about',
    component: about,
  }, 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// TODO: user auth
// router.beforeEach(async (to, from, next) => {
//   const auth = to.matched.some(record => record.meta.auth);
//   if(auth){
//     const hasAuthPassed = await utils.checkLoginStatus();
//     if (hasAuthPassed){
//       return next();
//     }
//     else{
//       return next("/login");
//     }
//   }else{
//     return next();
//   }
//   // const component = router.getMatchedComponents(to)[0];
//   // const resources = Object.keys(component.props ?? {}).filter(d =>
//   //   d.startsWith("_AUTH"),
//   // );
// });

export default router
