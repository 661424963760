<template>
  <v-container fluid fill-height class="backdrop-blur">
    <v-layout>
      <v-flex d-flex justify-center align-center class="text-xs-center">
        <v-row lign="center" justify="center">
          <v-expand-transition>
            <v-card
              v-if="!hideRegistration"
              class="py-9 px-10 floating-window"
              elevation-10
            >
              <v-form ref="form" @submit.prevent="submit" lazy-validation>
                <v-card-title class="justify-center text-h4" primary-title>
                  <img class="login-logo mr-3" src="@/assets/banner-signup.png" height="70"/>
                </v-card-title>
                <v-card-text class="mt-3">
                  <v-text-field
                    :label="$t('ui.signUp.email')"
                    :rules="[(v) => !!v || '']"
                    v-model="email"
                  />
                  <v-text-field
                    :label="$t('ui.signUp.account')"
                    :rules="[(v) => v.length > 0 && v.length <= 7]"
                    v-model="username"
                  />
                  <v-text-field
                    :label="$t('ui.signUp.password')"
                    :rules="[(v) => !!v || '']"
                    :type="showPass ? 'text' : 'password'"
                    :append-icon="showPass ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="showPass = !showPass"
                    v-model="password"
                  />
                  <v-row>
                    <v-col>
                      <v-text-field 
                        :label="$t('ui.signUp.firstName')"
                        :rules="[(v) => !!v || '']"
                        v-model="firstName" 
                      />
                    </v-col>
                    <v-col>
                      <v-text-field 
                        :label="$t('ui.signUp.lastName')"
                        :rules="[(v) => !!v || '']"
                        v-model="lastName" 
                      />
                    </v-col>
                  </v-row>
                  <v-text-field
                    :label="$t('ui.signUp.phoneNumber')"
                    :rules="[(v) => !!v || '']"
                    v-model="phone"
                  />
                </v-card-text>
                <v-card-actions class="">
                  <v-btn block type="submit" outlined color="accent"
                    >{{ $t("ui.signUp.signUp") }} </v-btn
                  >
                </v-card-actions>
              </v-form>
              <!-- <v-row align="center" class="my-1">
                <v-divider />{{ $t("ui.signUp.haveAnAccount") }} <v-divider />
              </v-row>
              <v-card-actions class="">
                <v-btn block to="/login" outlined color="primary"
                  >{{ $t("ui.signUp.login") }} </v-btn
                >
              </v-card-actions> -->
            </v-card>
          </v-expand-transition>
          <v-expand-transition class="delay-600">
            <v-card
              v-if="accountCreated"
              class="py-9 px-10 floating-window wide"
              elevation-10
            >
              <v-card-title primary-title> Welcome! {{ firstName }} {{ lastName }} </v-card-title>
              <v-card-text>
                <div class="reminder warning--text">
                  <!-- Please verify your email {{ email }} -->
                  Thanks for participating the beta!
                </div>
                <v-divider class="my-3" />
                <div class="reminder warning--text">
                  <!-- Please verify your email {{ email }} -->
                  UID: {{ createdUserDetails.user.uid }}
                </div>
              </v-card-text>
              <v-row>
                <v-row align="center" class="my-2">
                  <v-divider />Download the beta app.<v-divider />
                </v-row>
              </v-row>
              <v-btn 
                class="mt-4" 
                x-large 
                block 
                outlined 
                color="#3DDC84"
                @click="downloadBetaApp('android')"
              >Android <v-icon class="ml-2">mdi-android</v-icon>
              </v-btn>
              <v-btn 
                class="mt-4" 
                x-large 
                block 
                outlined 
                color="#fff"
                @click="downloadBetaApp('ios')"
              >iOS <v-icon class="ml-2">mdi-apple</v-icon>
              </v-btn>
              <!-- <v-btn class="mt-4" block to="/pricing" outlined color="accent">Select a subscription</v-btn>
              <v-btn class="mt-4" block to="/pricing" outlined color="secondary">Know more about MarineCom</v-btn>
              <v-btn class="mt-4" block to="/pricing" outlined color="primary">Start chating with your fellow on the sea</v-btn> -->
            </v-card>
          </v-expand-transition>
        </v-row>
        <div class="credits">
          <img src="@/assets/phasetech-logo.png" />
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import apis from "@/apis";

export default {
  data() {
    return {
      showPass: false,
      username: "",
      password: "",
      email: "",
      phone: "",
      firstName: "", 
      lastName: "", 
      hideRegistration: false,
      accountCreated: false,
      createdUserDetails: {}, 
    };
  },
  mounted() {
    // this.$paypal.Buttons().render("#pay-with-paypal")
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;
      this.createdUserDetails = await apis.createUser(
        this.username, 
        this.password, 
        this.email, 
        this.phone, 
        this.firstName, 
        this.lastName, 
      );
      this.hideRegistration = true;
      setTimeout(() => {
        this.accountCreated = true;
      }, 500);
    },
    createOrder(data){
      console.log(data);
      return {};
    }, 
    downloadBetaApp(platform){
      if(platform == "android"){
        window.open("https://marinecom.phasetech.tw/downloads/beta/android.apk", "_blank");
      }else{
        window.open("https://testflight.apple.com/join/iLCKCtdH", "_blank");
      }
    }
  },
};
</script>

<style>
.z1 {
  z-index: 1;
}
.floating-window {
  background: #0a0a0a60 !important;
  backdrop-filter: blur(5px) !important;
}
#hover-3d {
  transform-style: preserve-3d;
  z-index: 1;
}
.reminder {
  font-size: 120%;
}
.account-details {
  box-sizing: border-box;
  padding-left: 1rem;
  font-size: 150%;
  margin-top: 2rem;
}
.v-dialog {
  max-width: 50%;
}
.credits {
  left: 0;
  overflow: hidden;
  position: absolute;
  bottom: 0.5rem;
  width: 100%;
  text-align: center;
}
.credits img {
  max-height: 3.5rem;
}

@media only screen and (max-width: 600px) {
  .credits img {
    max-height: 2rem !important;
  }
}
</style>
