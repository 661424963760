// Pilipino
export default {
  login: "Mag-login",
  logout: "Mag-logout",
  messages: "Mga Mensahe",
  newMessage: "Bagong Mensahe",
  sendMessage: "Ipadala ang Mensahe",
  chat: "Usapang Magkasama",
  chatroom: "Kwarter ng Usapan",
  chatList: "Listahan ng Usapan",
  settings: "Mga Setting",
  language: "Wika",
  SOS: "SOS",
  medicalEmergency: "Emergency Medikal",
  overboard: "Lumampas sa Borda",
  pirate: "Pirata",
  sinkOrCrash: "Lumubog o Nabangga",
};
