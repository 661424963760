<template>
  <v-container fluid fill-height class="backdrop-blur">
    <v-layout>
      <v-flex d-flex justify-center align-center class="text-xs-center">
        <v-row lign="center" justify="center">
          <v-col md="4" cols="12">
            <v-card class="floating-window d-flex flex-column" elevation-10>
              <img width="100%" src="@/assets/messaging-example.jpg" />
              <v-card-title primary-title>
                {{ $t("ui.about.textMessaging.title") }}
              </v-card-title>
              <v-card-subtitle primary-title>
                {{ $t("ui.about.textMessaging.availability") }}
              </v-card-subtitle>
              <v-card-text> 
                <div class="description">
                  {{ $t("ui.about.textMessaging.description") }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="4" cols="12">
            <v-card class="floating-window d-flex flex-column" elevation-10>
              <img width="100%" src="@/assets/sos-example.png" />
              <v-card-title primary-title>
                {{ $t("ui.about.sos.title") }}
              </v-card-title>
              <v-card-subtitle primary-title>
                {{ $t("ui.about.sos.availability") }}
              </v-card-subtitle>
              <v-card-text> 
                <div class="description">
                  {{ $t("ui.about.sos.description") }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="4" cols="12">
            <v-card class="floating-window d-flex flex-column" elevation-10>
              <img width="100%" src="@/assets/crew-management-example.jpg" />
              <v-card-title primary-title>
                {{ $t("ui.about.crewManagement.title") }}
              </v-card-title>
              <v-card-subtitle primary-title>
                {{ $t("ui.about.crewManagement.availability") }}
              </v-card-subtitle>
              <v-card-text> 
                <div class="description">
                  {{ $t("ui.about.crewManagement.description") }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
    <div class="notes">
      <div class="note">{{ $t("ui.about.notes.unlimited") }}</div>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
  },
};
</script>

<style scoped>
.floating-window {
  background: #0a0a0a60 !important;
  backdrop-filter: blur(5px) !important;
  height: 100%;
}
.description{
  font-size: 115%;
}
.pricing{
  width: 100%;
  right: 0px;
  text-align: right;
  font-size: 135%;
}
.notes{
  position: absolute;
  bottom: 5px;
  text-align: center;
  width: 100%;
}
.notes .note{
  font-size: 12px;
  color: #b0b0b0;
}
</style>
