import en from "./en";
import id from "./id";
import fil from "./fil";
import vi from "./vi";
import th from "./th";
import zhTW from "./zhTW";

export default {
  en,
  id,
  fil,
  vi,
  th,
  zhTW,
};
