import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locale from "@/locale/index";

Vue.use(VueI18n)

export default new VueI18n({
  locale: navigator.language.split("-")[0].toLocaleLowerCase(), 
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: {
    en: { ui: locale.en },
    // id: { ui: locale.id },
    // fil: { ui: locale.fil },
    // vi: { ui: locale.vi },
    // th: { ui: locale.th },
    zh: { ui: locale.zhTW },
  },
})
