import request from "./_RequestBuilder";
import { jsonify } from "./_Utils";

const apiURL = process.env.VUE_APP_API_BASEURL

export default{
  async createUser(username, password, email, phone, firstName, lastName) {
    const response = await request.post(`${apiURL}/users/`, {
      username: username, 
      password: password, 
      firstName: firstName, 
      lastName: lastName, 
      email: email, 
      phone: phone, 
    })
    return jsonify(response);
  }, 
  async verifyEmail(uuid){
    const response = await request.get(`${apiURL}/mailing/verify?u=${uuid}`)
    return jsonify(response);
  }, 
  async login(account, password){
    const response = await request.post(`${apiURL}/auth/login`, {
      account: account,
      password: password,
    })
    return jsonify(response);
  }, 
  async logout() {
    const response = await request.post(`${apiURL}/auth/logout`, {}, {}, true);
    return jsonify(response);
  }, 
  async validateUserToken(){
    const response = await request.get(
      `${apiURL}/auth/validate`, {}, {}, true, 
    );
    return jsonify(response);
  }, 
  async refreshUserToken() {
    const response = await request.get(
      `${apiURL}/auth/refresh`, {}, {}, true,
    );
    return jsonify(response);
  },
  async getUserDetail() {
    const response = await request.get(
      `${apiURL}/users/`, {}, {}, true,
    );
    return jsonify(response);
  }, 
  async updateUserSubscriptionStatus(subscriptionData){
    const response = await request.post(
      `${apiURL}/payment/subscribe`, {
        subscriptionDetail: subscriptionData, 
      }, {}, true,
    );
    return jsonify(response);
  }, 
  async getSubscriptionDetail(){
    const response = await request.get(
      `${apiURL}/payment/subscription`, {}, {}, true,
    );
    return jsonify(response);
  }, 
}