<template>
  <div>
    <v-app-bar>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <!-- <v-toolbar-title>MarineCom</v-toolbar-title> -->
      <img class="mr-3" src="@/assets/banner.png" height="40"/>
    </v-app-bar>
    <v-bottom-sheet 
      v-model="drawer"
      inset
    >
      <v-list width="100%">
        <v-list-item-content>
          <v-btn v-for="(btn, idx) in items"
            :key="idx"
            @click="goto(btn.link)"
            grow
          >
            {{ btn.text }}
          </v-btn>
        </v-list-item-content>
      </v-list>
    </v-bottom-sheet>
  </div>
</template>

<script>

export default {
  props:{
    items: Array,
    title: String
  },
  data() {
    return {
      drawer: false
    }
  },
  methods: {
    goto(link){
      this.drawer = false;
      this.$router.push(link);
    }
  },
};
</script>

<style>
  .v-toolbar.v-toolbar--collapsed {
    max-width: 20%;
  }
</style>

