import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueCookies from 'vue-cookies'
import utils from './utils'

Vue.config.productionTip = false

Vue.use(VueCookies, {});

utils.checkLoginStatus().then((state) => {

  Vue.prototype.$authPassed = state;

  new Vue({
    router,
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount('#app');
});