// Chinese (Taiwan)
export default{
  navbar: {
    home: "首頁",
    about: "關於 MarineCom",
    pricing: "方案與定價",
    login: "登入",
    logout: "登出",
    dashboard: "主控板",
  },
  home: {
    title1: "一個",
    title2: "經濟實惠",
    title3: "的選項",
    title4: "讓你不間斷的,",
    title5: "與",
    title6: "家人, ",
    title7: "朋友, ",
    title8: "所有人",
    title9: "保持聯繫",
  },
  about: {
    textMessaging: {
      title: "文字訊息",
      availability: "現已推出",
      description: "無限量*的雙向通訊文字簡訊功能，讓你與親朋好友保持聯繫。或是在快要靠岸時告知您的生意夥伴即時在第一時間取得您豐富的漁獲。"
    },
    sos: {
      title: "SOS 求教",
      availability: "現已推出",
      description: "MarineCom SOS 在遭遇萬一的時候，直接將您與漁業署漁船監控中心建立連線，以在第一時間處理任何緊急事件。"
    },
    crewManagement: {
      title: "船員管理",
      availability: "2023 夏天推出",
      description: "船員管理功能重新打造船員打卡上下班、以及船員管理等功能，讓不論是船長或是船員都能夠流暢的工作。"
    },
    notes: {
      unlimited: "*實際最高流量視天氣、使用者位置、訊號覆蓋量等變因而異。",
    }, 
  },
  pricing: {
    vesselOwner: {
      title: "船長",
      description: {
        text: "船長方案包含所有船員的基本衛星通訊費用，以及一個 MarineCom 帳戶。",
        price: "$97.99 美金/月<br> + $34.95 一次性啟用費",
      },
    },
    crewMemberIndividual: {
      title: "船員 - 個人",
      description: {
        text: "船員 - 個人方案，給予船員與陸地保持聯繫的經濟實惠方案。",
        price: "<br>$3.99 美金/月",
      },
    },
    selectAndCheckOut: "選擇此方案並且付款", 
  },
  checkout: {
    checkout: "結帳",
    planSelected: "已選擇的方案：",
    pricing: {
      firstMonth: "美金第一個月，然後",
      perMonth: "美金每個月"
    }
  },
  login: {
    login: "登入",
    or: "或",
    signUp: "註冊",
    account: "帳號 (電子郵件)",
    password: "密碼",
  },
  signUp: {
    email: "電子郵件",
    account: "帳號(小於八個字元)",
    password: "密碼",
    firstName: "名字",
    lastName: "姓氏",
    phoneNumber: "電話號碼",
    signUp: "註冊",
    haveAnAccount: "已經有帳號了？",
    login: "登入",
  },
  dashboard: {
    dashboard: "主控板",
    accountDetail: {
      accountDetail: "帳號資訊",
      uid: "UID",
      email: "電子郵件",
      firstName: "名字",
      lastName: "姓氏",
      phoneNumber: "電話號碼",
    },
    vesselDetail: {
      vesselDetail: "船隻資訊"
    },
    subscriptionStatus: {
      subscriptionStatus: "訂閱與方案",
      activeSubscription: "有效的方案",
      noActiveSubscription: "尚未訂閱",
      viewPricing: "查看價格與方案",
      since: "自",
      renews: "下次更新",
    },
  },
}