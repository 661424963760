<template>
  <div>
    <navbarDesktop :items='items' :title="title" v-if="window_width > 600"></navbarDesktop>
    <navbarMobile :items='items' :title="title" v-else></navbarMobile>
  </div>
</template>

<script>
import navbarDesktop from "./navbar-desktop.vue";
import navbarMobile from "./navbar-mobile.vue";

export default {
  components: { navbarDesktop, navbarMobile },
  props:{
    items: Array,
    title: String
  },
  data: function(){
    return {
      window_width: 0,
    }
  },
  created() {
    window.addEventListener('resize', this.resized);
    this.resized();
  },
  methods: {
    resized(){ 
      this.window_width = window.innerWidth; 
    }
  },
};
</script>
